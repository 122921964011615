<template>
  <div class="px-2">
    <div
      v-for="(week, index) in calendar"
      :key="index"
      class="grid gap-0 grid-cols-7 relative"
    >
      <template v-if="index === 0">
        <div class="grid gap-0 grid-cols-7 absolute w-full">
          <div
            v-for="(day, index) in getDaysWeek()"
            :key="index"
            class="py-3 flex justify-center font-EffraR text-lg capitalize text-gray-600"
          >
            {{ day }}
          </div>
        </div>
      </template>
      <div
        v-for="(day, indeDay) in week"
        :key="indeDay"
        class="border border-solid border-gray-300 min-h-200"
      >
        <div class="flex justify-center pt-10 pb-3">
          <span
            :class="
              _moment()(new Date()).format('DD/MM/YYYY') ===
              _moment()(day).format('DD/MM/YYYY')
                ? 'bg-dokBlue-ultra text-white p-1 rounded-full w-8 h-8 flex items-center justify-center font-EffraM text-sm'
                : 'text-blackdok'
            "
            class="capitalize font-EffraR text-base"
            >{{
              _moment()(day).format("D") === "1"
                ? _moment()(day).format("D MMM")
                : _moment()(day).format("D")
            }}</span
          >
        </div>
        <div
          class="px-1 relative"
          v-if="eventsDay(_moment()(day).format('DD/MM/YYYY')).length"
        >
          <itemEvent
            v-for="(event, i) in eventsDay(_moment()(day).format('DD/MM/YYYY'))"
            :key="i"
            :event="event"
          ></itemEvent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment";
// Components
const itemEvent = () => import("./items/index");

export default {
  props: {
    events: Array,
    nowDate: Object
  },
  data() {
    return {
      calendar: []
    };
  },
  mounted() {
    EventBus.$on("generationDaysMonth", () => {
      this.generationDay();
    });
  },
  created() {
    this.generationDay();
  },
  methods: {
    _moment() {
      return moment;
    },
    getDaysWeek() {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(
          moment(this.nowDate)
            .day("Lundi")
            .add(i, "days")
            .format("ddd")
        );
      }

      return days;
    },
    generationDay() {
      this.calendar = [];
      let startDay = this.nowDate
        .clone()
        .startOf("month")
        .startOf("week");

      let endDay = this.nowDate
        .clone()
        .endOf("month")
        .endOf("week");

      let day = startDay.clone();

      while (day < endDay) {
        this.calendar.push(
          Array(7)
            .fill(0)
            .map(() => {
              let dayClone = day.clone();
              day.add(1, "days");
              return dayClone;
            })
        );
      }
    },
    eventsDay(date) {
      let dateDay = moment(date, "DD/MM/YYYY");
      let filterEvents = [];
      this.events.forEach(event => {
        let dateEvent = moment(event.startAt);

        if (dateEvent.isSame(dateDay, "day")) {
          filterEvents.push(event);
        }
      });

      return filterEvents;
    }
  },
  components: { itemEvent }
};
</script>

<style lang="scss"></style>
